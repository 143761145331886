import React, { Component } from 'react';
import { graphql }          from 'gatsby';
import Img                  from 'gatsby-image';

import HeaderImage   from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';

class NewsletterPage extends Component {
  render() {
    return (
      <>
        <HeaderImage>
          <Img fluid={this.props.data.headerImage.childImageSharp.fluid}/>
        </HeaderImage>
        <Wrapper>
          <Spacer/>
          <Heading subtitle={'Juwelier Hohage'}>Newsletter</Heading>
          <Spacer/>
          <h2 style={{textAlign: 'center'}}>Abonnieren Sie unseren Newsletter</h2>
          <div style={{ textAlign: 'center' }}>
            <iframe src="https://tbf4c09b0.emailsys1a.net/240/1377/7108404ce3/subscribe/form.html?_g=1713304408"
                    frameBorder="0" width="100%" height="550" />
          </div>
          <Spacer/>
        </Wrapper>
      </>
    )
  }
}

export default NewsletterPage;

export const query = graphql`
    query NewsletterPageQuery {
        headerImage: file(relativePath: {eq: "headers/newsletter.jpg"}) {
            name
            childImageSharp {
                fluid(maxWidth: 1980, maxHeight: 660, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
